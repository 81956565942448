// @flow

import { Big } from 'big.js';

export function decimal(value, decimal=2, locale='IN') {
    if ((value || value === 0) && !(value instanceof Big)) {
        value = new Big(value);
    }
    var number, final, remaining, part;
    if (value) {
        if (decimal === -1) {
            decimal = Math.max(value.c.length - value.e - 1,2);
            number = value.toFixed(decimal);
        } else if (decimal === -2) {
            decimal = Math.max(value.c.length - value.e - 1,0);
            number = value.toFixed(decimal);
        } else {
            number = value.toFixed(decimal)
        }
        var neg = '';
        if (number.substring(0,1)==='-') {
            neg = '-';
            number = number.substring(1);
        };
        if (decimal > 0) {
            final = number.substr(number.length-decimal-1);
            remaining = number.substring(0,number.length-decimal-1);
        } else {
            final = '';
            remaining = number;
        }
        if (locale === 'IN') {
            while (remaining.length>0) {
                if (remaining.length > 7) {
                    part = remaining.substr(remaining.length-7);
                    final = ',' + indianCommas(part) + final;
                    remaining = remaining.substring(0,remaining.length-7);
                } else {
                    final = indianSmall(remaining) + final;
                    remaining = '';
                }
            }
        } else {
            while (remaining.length>0) {
                if (remaining.length > 3) {
                    part = remaining.substr(remaining.length-3);
                    final = ',' + part + final;
                    remaining = remaining.substring(0,remaining.length-3);
                } else {
                    final = remaining + final;
                    remaining = '';
                }
            }
        }

        return neg+final;
    } else {
        return '';
    }
    
}

function indianSmall(number) {
    var final = '';
    if (number.length <= 3) {
        final = number;
    } else {
        final = number.substr(number.length-3);
        var remaining = number.substring(0,number.length-3);
        if (remaining.length <= 2) {
            final = remaining+','+final;
        } else {
            final = remaining.substr(remaining.length-2)+','+final;
            remaining = remaining.substring(0,remaining.length-2);
            final = remaining+','+final;
        }
    }
    return final;
}

function indianCommas(number) {
    return number.substring(0,2)+','+number.substring(2,4)+','+number.substring(4);
}

export function percent(value, decimal=0, locale='IN') {
    if ((value || value === 0) && !(value instanceof Big)) {
        value = new Big(value);
    }

    var number, final, remaining;
    
    if (value) {
        value = value.times(100);
        if (decimal === -1) {
            decimal = Math.max(value.c.length - value.e - 1,2);
            number = value.toFixed(decimal);
        } else if (decimal === -2) {
            decimal = Math.max(value.c.length - value.e - 1,0);
            number = value.toFixed(decimal);
        } else {
            number = value.toFixed(decimal)
        }
        var neg = '';
        if (number.substring(0,1)==='-') {
            neg = '-';
            number = number.substring(1);
        };
        if (decimal > 0) {
            final = number.substr(number.length-decimal-1);
            remaining = number.substring(0,number.length-decimal-1);
        } else {
            final = '';
            remaining = number;
        }
        while (remaining.length>0) {
            if (remaining.length > 3) {
                var part = remaining.substr(remaining.length-3);
                final = ',' + part + final;
                remaining = remaining.substring(0,remaining.length-3);
            } else {
                final = remaining + final;
                remaining = '';
            }
        }
        return neg+final+'%';
    } else {
        return '';
    }
    
}